<template>
<div class="flex flex-col self-stretch flex-grow md:w-full relative">
    <div class="absolute left-0 w-6 md:w-12 h-auto ">
        <svg data-fadeZoom fill="currentColor" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 76.2 62.8" style="enable-background:new 0 0 76.2 62.8;" xml:space="preserve">
            <g>
                <g>
                    <path class="st0" d="M0,31.4v31.4h31.4V31.4h-18c0-9.9,8-17.9,17.9-17.9V0C14,0,0,14.1,0,31.4z M76.2,13.5V0

C58.9,0,44.8,14.1,44.8,31.4v31.4h31.4V31.4H58.3C58.3,21.5,66.3,13.5,76.2,13.5z" />
                </g>
            </g>
        </svg>
    </div>

    <div class="min-h-[120px] mb-6 pl-8 md:pl-16">
        <h1 v-if="Object.values(slideContent.text)" data-fadeUp class="text-2xl md:text-5xl font-heading font-black capitalize tracking-normal" v-html="slideContent.text"></h1>
    </div>
    <div class="mb-6 pl-8 md:pl-16">
        <h1 v-if="Object.values(slideContent.cite)" data-fadeUp class="text-xl font-body ">{{ slideContent.cite }}</h1>
    </div>
</div>
</template>

<script>
import gsap from 'gsap'
export default {
    props: ['slideContent'],
    mounted() {
        gsap.from('[data-fadeup]', { y: 20, stagger: 0.3, autoAlpha: 0, delay: 0 })
        gsap.from('[data-fadeZoom]', { y: 20, scale: 3, autoAlpha: 0, delay: 0 })
    }
}
</script>
