<template>
<div class="flex flex-col self-stretch flex-grow md:w-2/3">
    <div class="min-h-[120px] mb-6">
        <h1 v-if="Object.values(slideContent.title)" :class="'text-'+slideContent.title.textColor" data-fadeUp class="text-5xl font-heading font-black capitalize" v-html="slideContent.title"></h1>
    </div>
</div>
<div class="flex flex-col self-stretch flex-grow w-full">
    <div v-if="images" class="grid grid-cols-3 md:grid-cols-6 gap-6">
        <div class="aspect-video " v-for="(image, i) in images" :key="i">
            <img data-fadeIn class="w-full h-full object-contain bg-white" :src="image.sizes['large']" />
        </div>
    </div>
</div>
</template>

<script>
import gsap from 'gsap'
export default {
    props: ['slideContent'],

    computed: {
        images() {
            return this.slideContent.slide_gallery
        },

    },

    mounted() {
        gsap.from('[data-fadeup]', { y: 20, stagger: 0.3, autoAlpha: 0, delay: 0 })
        gsap.from('[data-fadeIn]', { autoAlpha: 0, stagger: 0.1 })
    },

};
</script>
