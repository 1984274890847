<template>
<div class="">
    <h1 v-if="Object.values(slideContent.subTitle)" data-fadeUp :class="'text-'+slideContent.subTitle.textColor" class="text-6xl font-body font-bold">{{ slideContent.subTitle.text }}</h1>
</div>
<div class="flex flex-col lg:flex-row items-center lg:space-x-28 space">
    <div class="flex flex-col flex-grow lg:w-1/2 order-0">
        <p data-fadeup class="mt-12 md:text-xl xl:text-xl leading-tight">{{ slideContent.content.text }}</p>
    </div>
    <div class="lg:w-1/2 w-full mt-12 lg:mt-0">
        <img data-zoomIn :src="slideContent.large_image.sizes.large" alt="">
    </div>
</div>
</template>

<script>
import slideHeader from "@/components/slideHeader";
import gsap from 'gsap'
export default {
    props: ['slideContent'],
    components: {
        slideHeader
    },
    mounted() {
        gsap.from('[data-fadeup]', { y: 20, stagger: 0.3, autoAlpha: 0, delay: 0 })
        gsap.from('[data-zoomIn]', { scale: 0.9, autoAlpha: 0, delay: 0 })
    }
}
</script>
