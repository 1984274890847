<template>
<div v-if="currentSlide" :class="[backgroundColor.color, 'text-'+currentSlide.textColor]" :style="backgroundColor.isCustom ? {backgroundColor: backgroundColor.color} : ''" class="relative min-h-screen">
    <slideHeader class="z-50 relative" :slideContent="currentSlide" />
    <div class="max-w-6xl px-6 mx-auto h-full pb-32 z-0">
        <component :key="currentIndex" :is="currentSlideComponent" :slide-content="currentSlide" />
    </div>
</div>
<button v-if="currentIndex < slideShow.slides?.length-1" class="fixed bottom-12 right-12 h-12 w-12 bg-white p-4  shadow-xl rounded-full" @click="currentIndex++">
    <svg xmlns="http://www.w3.org/2000/svg" class="rotate-180" viewBox="0 0 32 32">
        <g id="_4-Arrow_Right" data-name="4-Arrow Left">
            <path d="M32,15H3.41l8.29-8.29L10.29,5.29l-10,10a1,1,0,0,0,0,1.41l10,10,1.41-1.41L3.41,17H32Z" />
        </g>
    </svg>
</button>
<button @click="currentIndex--" v-if="currentIndex>0" class="fixed bottom-12 left-12 h-12 w-12 bg-white p-4 rounded-full shadow-xl">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
        <g id="_4-Arrow_Left" data-name="4-Arrow Left">
            <path d="M32,15H3.41l8.29-8.29L10.29,5.29l-10,10a1,1,0,0,0,0,1.41l10,10,1.41-1.41L3.41,17H32Z" />
        </g>
    </svg>
</button>
</template>

<script>
import singleImageCenter from "./components/slides/singleImageCenter";
import fullScreenImage from "./components/slides/fullScreenImage";
import standardLayout from "./components/slides/standardLayout";
import textWithImage2Cols from "./components/slides/textWithImage2Cols";
import iconList from "./components/slides/iconList";
import twoCols from "./components/slides/twoCols";
import largeQuote from "./components/slides/largeQuote";
import contactScreen from "./components/slides/contactScreen";
import iconText from "./components/slides/iconText";
import videoScreen from "./components/slides/videoScreen";
import ImageGrid from "./components/slides/imageGrid";
import twoImageSplit from "./components/slides/twoImageSplit";
import slideHeader from "@/components/slideHeader";
export default {
    name: 'App',
    components: {
        fullScreenImage,
        textWithImage2Cols,
        standardLayout,
        iconList,
        twoCols,
        iconText,
        ImageGrid,
        twoImageSplit,
        contactScreen,
        videoScreen,
        singleImageCenter,
        slideHeader,
        largeQuote
    },
    data() {
        return {
            currentIndex: 0,
            slideShow: {}
        };
    },
    computed: {
        backgroundColor() {
            return {
                isCustom: this.currentSlide.backgroundColor == 'custom',
                color: this.currentSlide.backgroundColor == 'custom' ? this.currentSlide.custom_colour : 'bg-' + this.currentSlide.backgroundColor
            }
        },
        currentSlideComponent() {
            if (this.currentSlide) {
                return this.currentSlide.acf_fc_layout
            }
            return null
        },
        currentSlide() {
            if (this.slideShow.slides && this.slideShow.slides.length > 0) {
                if (this.currentIndex >= 0 && this.currentIndex < this.slideShow.slides.length) {
                    return this.slideShow.slides[this.currentIndex];
                }
            }

            return null;
        }
    },
    mounted() {

        let siteUrl = window.WPData != undefined ? window.WPData.siteUrl : 'http://localhost/gd-presentation/wp';
        let pageId = window.WPData != undefined ? window.WPData.currentPageId : 12;

        console.log(siteUrl)
        console.log(pageId)

        fetch(`${siteUrl}/wp-json/wp/v2/pages/${pageId}?_fields=acf&acf_format=standard`)
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }

                return response.json(); // Parse the JSON of the response

            })

            .then(data => {

                this.slideShow = data.acf // Spread the data into `this.data`

            })
            .catch(error => {
                console.error('There has been a problem with your fetch operation:', error);
            });
    },
}
</script>

<style>
* {
    margin: 0;
    padding: 0;
}

#app,
body,
html {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    background-color: black;
}

.font-heading {
    letter-spacing: -2px;
}

.slide {
    overflow: hidden;
    height: 100%;
    width: 100%;
}
</style>
