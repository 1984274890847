
export const transitionsMixin = {
 
  methods: {
    transitionIn(tl) {
     // let tl = gsap.timeline({ onComplete: () => this.$emit('nextPlease') });
      const transitionType = this.slideContent.transition.in;
      const duration = this.transitionDuration;

      switch (transitionType) {
        case 'fade':
          tl.fromTo('.slide', { autoAlpha: 0 }, { autoAlpha: 1, duration });
          break;
        case 'slideUp':
          tl.fromTo('.slide', { yPercent: 100 }, { yPercent: 0, duration });
          break;
        case 'slideDown':
          tl.fromTo('.slide', { yPercent: -100 }, { yPercent: 0, duration });
          break;
        case 'slideLeft':
          tl.fromTo('.slide', { xPercent: 100 }, { xPercent: 0, duration });
          break;
        case 'slideRight':
          tl.fromTo('.slide', { xPercent: -100 }, { xPercent: 0, duration });
          break;
        case 'scale':
          tl.fromTo('.slide', { scale: 0 }, { scale: 1, duration });
          break;
      }
    },
    transitionOut(tl) {
      //let tl = gsap.timeline({ onComplete: () => this.$emit('transitionCompleted') });
      const transitionType = this.slideContent.transition.out;
      const duration = this.transitionDuration;

      switch (transitionType) {
        case 'fade':
          tl.to('.slide', { autoAlpha: 0, duration });
          break;
        case 'slideUp':
          tl.to('.slide', { yPercent: -100, duration });
          break;
        case 'slideDown':
          tl.to('.slide', { yPercent: 100, duration });
          break;
        case 'slideLeft':
          tl.to('.slide', { xPercent: -100, duration });
          break;
        case 'slideRight':
          tl.to('.slide', { xPercent: 100, duration });
          break;
        case 'scale':
          tl.to('.slide', { scale: 0, duration });
          break;
        default:
          console.log("No transition out type matched");
      }
    }
  }
};
