<template>

    <div class="w-auto ">
        <img data-scrolltop class="h-auto w-full object-contain md:max-w-6xl mx-auto" :src="slideContent.image?.sizes['1536x1536']">
    </div>

</template>

<script>
import slideHeader from "@/components/slideHeader";
import gsap from 'gsap'
export default {
    props: ['slideContent'],
    components: {
        slideHeader
    },
    mounted() {
        gsap.from('[data-scrolltop]', { yPercent: 100, rotateZ: 30, autoAlpha: 0 })

    },
};
</script>

<style>
.fade-enter-active,
.fade-leave-active {
    transition: opacity 2s;
}

.fade-enter,
.fade-leave-to {
    opacity: 0;
}

.fade-enter.current,
.fade-leave-to.current {
    opacity: 1;
}

.fade-enter.previous,
.fade-leave-to.next {
    opacity: 0;
}

.fade-enter.next,
.fade-leave-to.previous {
    opacity: 0;
}
</style>
