<template>
<div v-if="isLoading" class="absolute inset-0 flex items-center justify-center">
    <!-- Add your spinning loader here -->
    <div class="loader border-t-orange"></div>
</div>
<video :src="slideContent.url" autoplay muted @loadedmetadata="onVideoLoaded" class="z-0 absolute w-full h-full top-0 left-0 object-cover">
</video>
</template>

  
  
<script>
import slideHeader from "@/components/slideHeader";
export default {
    props: ['slideContent'],
    components: {
        slideHeader
    },
    data() {
        return {
            isLoading: true
        };
    },
    methods: {
        onVideoLoaded() {
            // Hide the loader once the video is loaded
            this.isLoading = false;
        }
    }
}
</script>
  
  
<style>
/* Add styles for your loader */
.loader {
    border: 4px solid #372841;
    border-radius: 50%;
    width: 100px;
    height: 100px;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
</style>
