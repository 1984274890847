<template>
<img data-fadeIn v-for="(image, i) in images" :key="i" :src="image.sizes['1536x1536']" :class="currentImageIndex == i ? 'opacity-100 scale-100' : 'opacity-0 scale-75'" class="duration-700 absolute top-0 left-0 w-full h-full object-cover" />
<ul v-if="images.length > 1" data-fadeUp class="fixed left-1/2 bottom-10 -translate-x-1/2 flex gap-1 w-fit bg-black bg-opacity-30 p-2 flex-wrap">
    <li v-for="(image, i) in images" :key="i" class="cursor-pointer max-w-12 aspect-square" @click="changeImage(i)" :class="{ 'bg-blue': currentImageIndex === i, 'bg-black': currentImageIndex !== i }">
        <img data-fadeIn class="w-full h-full object-cover p-[1px]" :src="image.sizes['medium']" :class="{ 'bg-blue': currentImageIndex === i, 'opacity-50 hover:opacity-100': currentImageIndex !== i }" />
    </li>
</ul>
</template>

<script>
import gsap from 'gsap'
export default {
    props: ['slideContent'],

    data() {
        return {
            currentImageIndex: -1,
            previousImageIndex: 0,
            nextImageIndex: 0,
            intervalId: null // to store the interval ID
        };
    },
    computed: {
        images() {
            return this.slideContent.slide_gallery
        },
        currentImage() {
            return this.images[this.currentImageIndex];
        }
    },
    created() {
        gsap.set('[data-fadeIn]', { autoAlpha: 0 })

        if (this.images.length < 1) {
            return
        }

        this.currentImageIndex = Math.floor(Math.random() * this.images.length);
        setTimeout(() => {
            this.startSlideshow();
        }, 2000);

    },
    mounted() {
        gsap.from('[data-fadeUp]', { yPercent: 4, autoAlpha: 0, stagger: 2 })
        gsap.from('[data-fadeIn]', { autoAlpha: 0 })
    },
    methods: {
        startSlideshow() {
            this.intervalId = setInterval(() => {
                this.previousImageIndex = this.currentImageIndex;
                this.currentImageIndex = (this.currentImageIndex + 1) % this.images.length;
                this.nextImageIndex = (this.currentImageIndex + 1) % this.images.length;
            }, 4000);
        },
        changeImage(index) {
            this.currentImageIndex = index;
            clearInterval(this.intervalId); // Clear the interval
        }
    }
};
</script>

<style>
.fade-enter-active,
.fade-leave-active {
    transition: opacity 2s;
}

.fade-enter,
.fade-leave-to {
    opacity: 0;
}

.fade-enter.current,
.fade-leave-to.current {
    opacity: 1;
}

.fade-enter.previous,
.fade-leave-to.next {
    opacity: 0;
}

.fade-enter.next,
.fade-leave-to.previous {
    opacity: 0;
}
</style>
