<template>
<div v-if="slideContent" class="absolute h-full w-full  top-0 left-0 flex flex-col md:flex-row">
    <div class="w-full h-1/2 md:w-1/2 md:h-full" :style="{backgroundColor: slideContent.color_left}">
        <img data-fadeFromRight :class="slideContent.contain ? 'object-contain' : 'object-cover'" class="h-full w-full" v-if="slideContent.image_left?.sizes?.large" :src="slideContent.image_left?.sizes?.large">
    </div>
    <div class="w-full h-1/2 md:w-1/2 md:h-full" :style="{backgroundColor: slideContent.color_right}">
        <img data-fadeFromLeft :class="slideContent.contain ? 'object-contain' : 'object-cover'" class="h-full w-full" v-if="slideContent.image_right?.sizes?.large" :src="slideContent.image_right?.sizes?.large">
    </div>
</div>
</template>
<script>
import gsap from 'gsap'
export default {
    props: ['slideContent'],
    mounted() {
        gsap.from('[data-fadeFromLeft]', { xPercent: 10, autoAlpha: 0 })
        gsap.from('[data-fadeFromRight]', { xPercent: -10, autoAlpha: 0 })
    },

};
</script>

<style>
.fade-enter-active,
.fade-leave-active {
    transition: opacity 2s;
}

.fade-enter,
.fade-leave-to {
    opacity: 0;
}

.fade-enter.current,
.fade-leave-to.current {
    opacity: 1;
}

.fade-enter.previous,
.fade-leave-to.next {
    opacity: 0;
}

.fade-enter.next,
.fade-leave-to.previous {
    opacity: 0;
}
</style>
