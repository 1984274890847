<template>
  <section
    class="slide"
    :style="{backgroundColor: slideContent.backgroundColor}"
  >
    <h1 class="title">
      {{ slideContent.title }}
    </h1>
  </section>
</template>
<script>
import gsap from "gsap";
import { transitionsMixin } from "../../transitionsMixin"; // Adjust the path as necessar
export default {
    // DO NOT EDIT BELOW
    mixins: [transitionsMixin],
    props: ['slideContent', 'transitionDuration'],
    computed: {
        tl() {
            return gsap.timeline({ onComplete: () => this.$emit('nextPlease') })
        }
    },
    mounted() {
        this.transitionIn(this.tl)
        this.slideAnimation()
        this.transitionOut(this.tl)
    },
    methods: {
        slideAnimation() {
            this.tl.to('.title', { y: 600, duration: 2 })
                .to('.title', { x: -200, duration: 3 })
        },
    },

}
</script>
