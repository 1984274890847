<template>
<div class="flex flex-col self-stretch flex-grow w-2/3">
    <div class="min-h-[120px] mb-6">
        <h1 v-if="slideContent.title && Object.values(slideContent.title)" :class="'text-'+slideContent.title.textColor" data-fadeUp class="text-5xl font-heading font-black capitalize" v-html="slideContent.title"></h1>
    </div>
</div>
<div v-if="images" class="flex flex-col self-stretch flex-grow w-full">
    <div class="grid grid-cols-1 md:grid-cols-2 gap-12">
        <div data-fadeUp class="flex gap-x-4 items-center" v-for="(item, i) in images" :key="i">
            <img class="h-full object-contain bg-white w-1/3 " :src="item.icon.sizes['large']" />
            <div class="flex flex-col w-2/3 space-y-2">
                <h3 class="text-2xl font-heading font-bold tracking-normal">{{ i+1 + '. '+item.title }}</h3>
                <p>
                    {{ item.content }}
                </p>
            </div>
        </div>

    </div>
</div>
</template>

<script>
import slideHeader from "@/components/slideHeader";
import gsap from 'gsap'

export default {
    props: ['slideContent'],
    components: {
        slideHeader
    },

    computed: {
        images() {
            return this.slideContent.Item
        },

    },

    mounted() {
        gsap.from('[data-fadeup]', { y: 20, stagger: 0.3, autoAlpha: 0, delay: 0 })
        gsap.from('[data-fadeIn]', { autoAlpha: 0, stagger: 0.1 })
    },

};
</script>
