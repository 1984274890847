<template>
<div class="w-full self-stretch flex-grow flex flex-col  h-[calc(100vh-80px)] md:h-[calc(100vh-200px)]">
    <div class="flex flex-col self-stretch flex-grow md:w-2/3 ">
        <div class="min-h-[120px] mb-6">
            <h1 data-fadeUp class="text-5xl font-heading font-black capitalize text-white">Get in touch.</h1>
        </div>
    </div>
    <div class="flex flex-col self-stretch flex-grow md:w-2/3" :class="'text-'+textColor">
        <a :href="'tel:'+slideContent.phone" :class="'text-'+textColor" data-fadeUp class="text-2xl md:text-4xl font-body font-black  w-full">{{ slideContent.phone }}</a>
        <a :href="'mailto:'+slideContent.email" data-fadeUp class="text-2xl md:text-4xl font-body font-black  w-full">{{ slideContent.email }}</a>
    </div>
    <div class="flex flex-col h-full self-stretch flex-grow md:w-2/3 pb-12 mt-auto ">
        <div class="mt-auto">
            <p :class="'text-'+textColor" class="font-body font-bold">© Greenwich Design {{currentYear}}. All rights reserved</p>
            <a class="mt-auto font-body font-bold text-white" href="https://greenwich-design.co.uk">greenwich-design.co.uk</a>
        </div>
    </div>
</div>
</template>

<script>
import gsap from 'gsap'
export default {
    props: ['slideContent'],
    computed: {
        currentYear() {
            return new Date().getFullYear();
        }
    },
    mounted() {
        gsap.from('[data-fadeup]', { y: 20, stagger: 0.3, autoAlpha: 0, delay: 0 })
    }
}
</script>
