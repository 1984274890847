<template>
<div class="flex flex-col self-stretch flex-grow md:w-2/3">
    <div class="min-h-[120px] mb-6">
        <h1 v-if="Object.values(slideContent.title)" :class="'text-'+slideContent.title.textColor" data-fadeUp class="text-5xl font-heading font-black capitalize" v-html="slideContent.title.text"></h1>
    </div>
    <div class="">
        <h1 v-if="Object.values(slideContent.subTitle)" data-fadeUp :class="'text-'+slideContent.subTitle.textColor" class="text-4xl font-body font-bold">{{ slideContent.subTitle.text }}</h1>
    </div>
</div>
</template>

<script>
import gsap from 'gsap'
export default {
    props: ['slideContent'],
    mounted() {
        gsap.from('[data-fadeup]', { y: 20, stagger: 0.3, autoAlpha: 0, delay: 0 })
    }
}
</script>
