<template>
<div class="flex flex-col self-stretch flex-grow md:w-2/3">
    <div class="min-h-[120px] mb-6">
        <h1 v-if="Object.values(slideContent.title)" :class="'text-' + slideContent.title.textColor" data-fadeUp class="text-5xl font-heading font-black capitalize" v-html="slideContent.title"></h1>
    </div>
</div>
<div class="flex flex-col self-stretch flex-grow w-full">
    <div class="grid grid-cols md:grid-cols-3 gap-6">
        <div class="flex flex-col space-y-6">
            <img v-for="(image, i) in slideContent.images_col_1" :key="i" data-fadeup :src="image.sizes['large']" class="w-full object-cover bg-white" />
        </div>
        <div class="flex flex-col space-y-6">
            <img v-for="(image, i) in slideContent.images_col_2" :key="i" data-fadeup :src="image.sizes['large']" class="w-full object-cover bg-white" />
        </div>
        <div class="flex flex-col space-y-6">
            <img v-for="(image, i) in slideContent.images_col_3" :key="i" data-fadeup :src="image.sizes['large']" class="w-full object-cover bg-white" />
        </div>
    </div>
</div>
</template>

<script>
import slideHeader from "@/components/slideHeader";
import gsap from 'gsap';

export default {
    props: ['slideContent'],
    components: {
        slideHeader
    },
    mounted() {
        gsap.from('[data-fadeup]', { y: 20, stagger: 0.1, autoAlpha: 0, delay: 0 });
        gsap.from('[data-fadeIn]', { autoAlpha: 0, stagger: 0.1 });
    },
};
</script>

<style>
/* No need to specify aspect ratio classes */
</style>
