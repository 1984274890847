<template>
<div class="flex w-full justify-between items-center mb-12 pb:mb-32 z-50 text-white max-w-6xl mx-auto  px-6 min-h-20">
    <div>
        <h5 v-if="slideContent.slide_pre_title" data-titleTop class="text-sm font-bold mb-6">{{ slideContent.slide_pre_title }}</h5> <!-- pre header-->
        <div class="flex space-x-6 items-center">
            <span data-bar :class="'bg-'+slideContent.accentColor" class="h-1 w-14 block"></span>
            <h5 data-titleRight class="text-sm font-bold">{{ slideContent.side_title }}</h5> <!-- inner header-->
        </div>
    </div>
    <img class="w-12 h-auto" data-logo :src="themeUrl+'/smalllogo.svg'" alt="">
</div>
</template>

<script>
import gsap from 'gsap'
export default {
    props: ['slideContent'],
    data() {
        return {
            themeUrl: ''
        };
    },

    mounted() {

        gsap.from('[data-logo]', { x: 20, autoAlpha: 0 })
        gsap.from('[data-titleTop]', { y: 10, autoAlpha: 0 })
        gsap.from('[data-bar]', { width: 0, delay: 0.25 })
        gsap.from('[data-titleRight]', { x: -10, autoAlpha: 0, delay: 0.5 })
        let themeUrl = window.WPData != undefined ? window.WPData.themeUrl : '/';
        this.themeUrl = themeUrl

        //   this.assetsFolder = 
    },
    beforeUnmount() {
        gsap.to('[data-logo]', { x: 20, autoAlpha: 0 })
        gsap.to('[data-titleTop]', { y: 10, autoAlpha: 0 })
        gsap.to('[data-bar]', { width: 0, delay: 0.25 })
        gsap.to('[data-titleRight]', { x: -10, autoAlpha: 0, delay: 0.5 })
    }

}
</script>
